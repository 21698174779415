.App {
  text-align: center;
}

.mockup-content-container {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.tag-line {
  font-family: "Righteous";
  color: #282828;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

img {
  width: 100%;
  height: auto;
  left: 0px;
  right: 0px;
}
